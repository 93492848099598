import { LegalPageFooter } from 'components/LegalPageFooter';
import { Layout } from 'components/Layout';
import { Seo } from 'components/Seo';

import { Container, HeroContainer, Section } from 'components/Container';
import { NumberedListNested, NumberedListGroup, List } from 'components/List';
import { proTermsOfUseClauses as clauses, proTermsOfUseHeaders } from 'page-data/proTermsOfUse';

import type { FC } from 'react';
import type { LocalizedPageProps } from '@sharesight/gatsby-plugin-sharesight-localization';

const lastUpdatedDate: Date = new Date('2023-02-20');

const SharesightProfessionalTermsOfUse: FC<LocalizedPageProps> = () => (
  <Layout>
    <Seo />
    <HeroContainer background="cream">
      <Section>
        <h1>Terms of Use – Sharesight Professional</h1>
        <p id="header">
          <strong>Sharesight Terms of Use – Professional Partner Plan</strong>
          <br />
          (to be read in conjunction with any other agreement which describes the products and
          services we will provide to you, which we may agree with you from time to time (the{' '}
          <strong>Terms of Agreement</strong>)).
        </p>
        <br />
        <List isNumbered noIndent bulletPadding={1} itemGap={0} items={proTermsOfUseHeaders} />
      </Section>
    </HeroContainer>

    <NumberedListGroup>
      {clauses.map(c => (
        <Container key={c.header}>
          <Section>
            <NumberedListNested header={c.header} clauses={c.clauses} />
          </Section>
        </Container>
      ))}
    </NumberedListGroup>

    {/* Floating to the top button */}
    <LegalPageFooter elementId="header" date={lastUpdatedDate} />
  </Layout>
);

export default SharesightProfessionalTermsOfUse;
